$navExtraHeight: 1.5rem;

.has-navbar-fixed-top {
  padding-top: 3.25rem + $navExtraHeight !important;
}

.navbar {
  //.is-selected {
  //  &:after {
  //    content: "•";
  //    position: absolute;
  //    color: $primary;
  //  }
  //}
  //top: $navExtraHeight !important;

  a.logo {
    display: flex;
    background-color: $white;
    padding: 0.5rem;
  }

  .navbar-brand {
    margin-left: 0!important;
    margin-right: 1rem;

    .navbar-item {
      &:first-child {
        padding: 0;
      }
    }
  }

  &.is-dark {
    background-color: transparent;

    //.navbar-brand {
    //  //margin-left: -0.5rem;
    //  //margin-right: 1.5rem;
    //  .navbar-item {
    //    &:first-child {
    //      margin-left: 0.5rem;
    //    }
    //  }
    //}
    .navbar-dropdown {
      background-color: $dark;
      border-color: darken($dark, 20%);
    }
    label, .label {
      color: invert($dark);
    }
  }

  .navbar-start {
    font-size: 0.875rem;
  }

  .navbar-burger {
    height: auto;
  }

  .nav-extra-links {
    //ul {
    //  display: flex;
    //  li {
    //    //display: inline-block;
    //    //padding: 0 0.5rem 0 0;
    //    padding: 0 0.75rem 0 0;
    //  }
    //  //li:last-child {
    //  //  padding: 0;
    //  //}
    //}

    a {
      font-size: $size-7;
      text-transform: uppercase;
    }
  }

  @include until($custom-mobile-breakpoint) {
    .navbar-start {
      font-size: 1rem;
    }
  }

  @include until($mobile-breakpoint) {
    .is-cart {
      white-space: pre-line;
      max-width: 36px;
    }
  }

  @include until($navbar-breakpoint) {
    .navbar-menu {
      overflow-x: hidden !important;
    }
  }

  @media screen and (min-width: $navbar-breakpoint) and (max-width: 800px) {
    .navbar-start {
      overflow: scroll;
      max-width: 23rem;
      //.navbar-item:nth-child(n+3) {
      //  display: none;
      //}
    }
  }
  @media screen and (min-width: 801px) and (max-width: 1024px) {
    .navbar-start {
      overflow: scroll;
      max-width: 30rem;
      //.navbar-item:nth-child(n+4) {
      //  display: none;
      //}
    }
  }
  @media screen and (min-width: 1025px) and (max-width: 1200px) {
    .navbar-start {
      overflow: scroll;
      max-width: 34rem;
      //.navbar-item:nth-child(n+5) {
      //  display: none;
      //}
    }
  }
}

.nav-extra {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: $navExtraHeight;
  line-height: $navExtraHeight;
  margin: 0;
  padding: 0;
  z-index: 30;
  background-color: lighten($brand-green, 50);

  display: flex;
  justify-content: flex-end;

  ul {
    display: flex;
    li {
      //display: inline-block;
      //padding: 0 0.5rem 0 0;
      padding: 0 0.75rem 0 0;
    }
    //li:last-child {
    //  padding: 0;
    //}
  }

  a {
    font-size: $size-7;
    text-transform: uppercase;
  }
}

@include from($large-mobile-breakpoint) {
  .nav-extra ~ .navbar  {
    top: $navExtraHeight !important;
  }
}
@include until($large-mobile-breakpoint) {
  .navbar  {
    top: 0 !important;
  }
  .nav-extra {
    display: none !important;
  }
  .has-navbar-fixed-top {
    padding-top: 3.25rem !important;
  }
}

.navbar-item.is-mega {
  position: static;

  .is-collapsed {
    .navbar-item:nth-child(n+7) {
      display: none;
    }
  }
  @include until($large-mobile-breakpoint) {
    .navbar-item, .navbar-item:nth-child(n+7) {
      display: inline-block!important;
    }
    .navbar-item.heading {
      display: block;
    }
    .navbar-item:nth-child(n+9) {
      display: none!important;
    }
  }
}

.navbar {
  .is-button, .is-link {
    @extend a.navbar-item;
  }
}