
@import "../../../frontend/public/stylesheets/style";

@import "../../../node_modules/bulma-timeline/src/sass";
@import "../../../node_modules/bulma-switch/src/sass";
//@import "../../../node_modules/bulma-slider/src/sass";


//$scheme-main: $black;

section, .section {
  overflow: unset;
  .container {
    max-width: 100%;
  }
}

$navExtraHeight: 0rem;
.has-navbar-fixed-top {
  padding-top: 3.25rem + $navExtraHeight !important;
}
.navbar {
  a.logo {
    background-color: transparent;
  }

  .navbar-center {
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    flex-grow: 1;
    flex-shrink: 0;
    flex-direction: row;
  }


  .navbar-item {
    &.is-fullwidth-input {
      width: 100%;
      .field, .control {
        width: 100%;
      }
    }
  }

  &.is-dark {
    background-color: $dark;

    .navbar-item {
      color: $light;
    }
  }
}

.has-badge-rounded {
  &:after {
    box-shadow: 3px 3px 18px 1px $dark;
    font-weight: bolder;
  }
}

//.is-chart {
//  min-height: 300px;
//  height: 100%;
//  &.is-small {
//    min-height: 100px;
//  }
//  &.is-large {
//    min-height: 500px;
//  }
//}
.is-chart {
  height: 320px;
  &.is-small {
    height: 160px;
  }
  &.is-large {
    height: 540px;
  }
}

//
//.autocomplete {
//  /*the container must be positioned relative:*/
//  position: relative;
//  display: inline-block;
//}
//.autocomplete-items {
//  &.has-search-results {
//    @extend .box;
//    position: absolute;
//    border: 1px solid $brand-light-pink;
//    //border-bottom: none;
//    //border-top: none;
//    z-index: 99;
//    /*position the autocomplete items to be the same width as the container:*/
//    top: 100%;
//    left: 0;
//    right: 0;
//  }
//
//  .row {
//    padding: 10px;
//    cursor: pointer;
//    background-color: #fff;
//    border-bottom: 1px solid #d4d4d4;
//
//    &:hover {
//      background-color: #e9e9e9;
//    }
//  }
//}
//.autocomplete-active {
//  /*when navigating through the items using the arrow keys:*/
//  background-color: $secondary !important;
//  color: #ffffff;
//}
//

//.table {
//  tr.is-selected {
//    background-color: $brand-light-teal;
//    color: invert($brand-light-teal);
//  }
//}

@import "sun";
@import "strategy";