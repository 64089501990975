
//h1, h2, h3, h4, h5, h6 {
//  font-family: $family-sans-serif;
//}
$html_headers: h1, h2, h3, h4, h5, h6;
@each $h in $html_headers {
  $i: index($html_headers, $h);
  #{$h} {
    @extend .is-size-#{$i};
  }
  .#{$h} {
    //font-family: $family-sans-serif;
    @extend .is-size-#{$i};
  }
}

.is-family-serif {
  font-family: $family-serif;
}
.is-family-sans-serif {
  font-family: $family-sans-serif;
}
.is-family-secondary {
  label, &label {
    font-weight: normal !important;
  }
}


.content {
  h1, h2, h3, h4, h5, h6 {
    font-weight: inherit;
  }
  blockquote {
    @extend .is-size-4;
  }
}

.blockquote, .quote {
  @extend .is-size-3;
}


.is-size-smallest {
  font-size: $size-smallest !important;
}

// Overlay
.is-overlay-container {
  position: relative;
}
.is-overlay-full {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.is-overlay-half {
  position: absolute;
  width: 100%;
  height: 50%;
}
.is-overlay-half-bottom {
  @extend .is-overlay-half;
  bottom: 0;
}


.is-borderless {
  border-color: transparent;
}

.has-text-line-through {
  text-decoration: line-through !important;
}


.is-visible {
  display: inherit!important;
}

.has-cursor-pointer {
  cursor: pointer;
}

// NOT NEEDED as it's automatically generated from $custom-colors
//.has-background-light-gray-pink {
//  background-color: $brand-light-gray-pink !important;
//}
//.has-background-light-gray-teal {
//  background-color: $brand-light-gray-teal !important;
//}
//.has-background-green {
//  background-color: $brand-green;
//}
//.has-background-light-green {
//  background-color: $brand-light-green;
//}
//.has-background-dark {
//  .title {
//    color: $brand-light-teal;
//  }
//  .subtitle {
//    color: $brand-green;
//  }
//}


.has-hero-height {
  min-height: 38rem;
}

.is-vcentered {
  align-items: center
}



.no-overflow-x {
  overflow-x: hidden;
}
.no-overflow-y {
  overflow-y: hidden;
}
.no-overflow {
  overflow: hidden;
}



.has-margin-top {
  margin-top: 3rem;
}
.has-margin-bottom {
  margin-bottom: 3rem;
}
.has-margin-left {
  padding-left: 1.5rem;
}
.has-margin-right {
  padding-right: 1.5rem;
}
.has-margin-horizontal {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.has-margin-vertical {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.nowrap {
  flex-wrap: nowrap;
  white-space: nowrap;
}