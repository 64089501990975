
.button {
  &.is-text {
    text-decoration: none;
  }
  &.is-clear {
    padding: 0;
    border: none;
    background: none;
    &:hover {
      background: inherit;
    }
  }
}

.is-dark {
  .button {
    &.is-clear {
      @extend .button.is-dark;
      @extend .button.is-clear;
      &:hover {
        border-radius: 0;
      }
    }
  }
}

@include until($large-mobile-breakpoint) {
  .button {
    &.is-small-mobile {
      border-radius: $radius-small;
      font-size: $size-small!important;;
    }
    &.is-normal-mobile {
      font-size: $size-normal!important;;
    }
    &.is-medium-mobile {
      font-size: $size-medium!important;;
    }
    &.is-large-mobile {
      font-size: $size-large!important;;
    }
  }
}

//.is-dark {
//  .button {
//    &.is-clear {
//      padding: 0;
//      border: none;
//      background: none;
//      &:hover {
//        background: inherit;
//      }
//    }
//  }
//}