.testimonial {
  q {
    display: block;/* or inline-block */
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 6em;
    //line-height: 1.8em;
  }
  q:before {
    content: '❝';
    color: $primary;
  }
  q:after {
    position: relative;
    right: 0;
    content: '...';
  }
  q:hover {
    max-height: initial;
    &:after {
      color: $primary;
      position: relative;
      //content: close-quote;
      content: '❞';
    }
  }
}