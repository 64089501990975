
.isEAM ~ .section {
  padding-top: 0!important;
  margin-top: 0!important;
}

section, .section {
  overflow: hidden;

  &.has-margin-top {
    margin-top: 3rem;
  }
  &.has-margin-bottom {
    margin-bottom: 3rem;
  }
  &.has-margin-left {
    padding-left: 1.5rem;
  }
  &.has-margin-right {
    padding-right: 1.5rem;
  }
  &.has-margin-horizontal {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  &.has-margin-vertical {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  &.is-image-with-box {
    .image, .cover-fill, .cover-contain {
      min-height: 300px;
    }
    .box {
      &.is-main {
        min-height: 300px;
        padding: 3rem;
      }
    }
  }
}

//.section.is-title-subtitle {
//  //padding-bottom: 0;
//  text-align: center;
//  .title {
//    color: $secondary;
//    font-size: $size-4!important;
//    &:before, &:after {
//      content: "∙";
//    }
//  }
//}