/*
product
  gallery
  details

  image
  details
 */

.product {

  .title {
    @extend .is-marginless;
    color: $secondary;
  }
  .subtitle {
    @extend .is-marginless;
    font-family: $family-sans-serif;
    font-size: $size-7;
    color: $primary;
    text-transform: uppercase;
  }

  .pricing {
    @extend .has-text-centered;
    @extend .is-inline;
    .full-price {
      @extend .is-size-7;
      color: $brand-light-teal;
      text-decoration: line-through;
      display: inline-block;
      margin-right: 0.5rem;
    }
    .price {
      @extend .is-size-6;
      color: $brand-pink;
      display: inline-block;
      font-family: $family-secondary;
    }
  }
  .action {
    .pricing {
      text-align: left !important;
      .full-price {
        @extend .is-size-7;
        display: block;
        margin: 0 !important;
      }
      .price {
        @extend .is-size-4;
        display: block;
      }
    }
  }

  &.is-product-overview {
    .has-background-dark {
      color: $brand-light-green;
    }
    .gallery {
      //@extend .box;
      .is-main-image {
        min-height: 400px;
        @include until($large-mobile-breakpoint) {
          min-height: 200px;
        }
      }
    }
    .details {
      //@extend .box;
      @extend .has-background-dark;
      @extend .has-text-light;
      ul {
        margin-bottom: 0.5rem;
        li {
          .material-icons {
            vertical-align: middle;
            margin-right: 1rem;
            font-size: 1.8em;
            color: $brand-green;
            //float: left;
          }
        }
      }
    }

    .bubble-clearfix {
      padding-top: 5rem;
      @include until($tablet) {
        padding: 0;
      }
    }
    .bubble {
      top: -2rem;
      right: -1rem;
      @include until($tablet) {
        top: -6rem;
        right: -1rem;
      }
    }

    //.action {
    //  margin-top: 2rem;
    //}
    .stock {
      margin-top: 1rem;
    }

    @include until($custom-mobile-breakpoint) {
      padding: 3rem 0.5rem 1rem 0.5rem;

      .add-to-cart {
        font-size: 1rem;
      }

      .gallery {
        padding: 0.5rem;
      }

      .tile.is-parent {
        padding-bottom: 0;
      }
    }

  }

  &.is-product-page {
    @extend .has-background-grey-light;
    .gallery {
      .is-main-image {
        min-height: 400px;
        @include until($large-mobile-breakpoint) {
          min-height: 300px;
          background-size: cover;
        }
      }
    }
    .details {
      .subtitle {
        //color: $brand-teal;
        font-size: $size-7;
      }
      ul {
        margin-bottom: 0.5rem;
        li {
          .material-icons {
            vertical-align: middle;
            margin-right: 1rem;
            font-size: 1.8em;
            color: $brand-green;
            //float: left;
          }
        }
      }
    }

    .heading {
      padding-top: 1rem;
    }

    .bubble-clearfix {
      padding-top: 3rem;
      @include until($tablet) {
        padding: 0;
      }
    }
    .bubble {
      top: -3rem;
      right: -1rem;
      @include until($tablet) {
        top: -6rem;
        right: 0.1rem;
      }
    }

    //.action {
    //  margin-top: 2rem;
    //}
    .pricing {
      float: right;
      text-align: right !important;
      .full-price {
        color: $brand-blue;
        display: block;
        margin: 0;
      }
    }
    //.stock {
    //  @include mobile {
    //    position: absolute;
    //    left: 0;
    //  }
    //}
    .stock {
      margin-top: 0.5rem;
    }

    //.colors ~ .action {
    //  margin-top: 0.5rem;
    //}
    .action {
      margin-top: 1rem;
    }

    .gifts {
      max-width: 400px;
      .checkbox {
        padding-top: 0.5rem;
      }
    }

    .sizes {
      .columns {
        margin: 0;
        .column {
          padding: 0.35rem;
        }
      }
    }

    @include until($custom-mobile-breakpoint) {
      padding: 2rem 0.5rem 1rem 0.5rem;
      overflow: hidden;

      .breadcrumb {
        margin: 0!important;
      }

      .action {
        margin-top: 1rem;
      }
      .add-to-cart {
        font-size: 1rem;
      }

      .gallery {
        padding: 0.5rem;
      }

      .tile.is-parent {
        padding-bottom: 0;
      }

      .title {
        font-size: 1.5rem !important;
      }

      .gifts {
        font-size: $size-7;
        .pricing {
          .price {
            font-size: $size-7 !important;
          }
        }
      }

      .gifts, .sizes {
        .columns {
          margin: 0;
          .column {
            padding: 0.35rem;
          }
        }
      }
    }

    @include until($mobile-breakpoint) {
      .action {
        .button {
          font-size: $size-6 !important;
        }
        //.pricing {
        //  .price {
        //    font-size: $size-6 !important;
        //  }
        //}
      }
    }

    @include until($large-mobile-breakpoint) {
      .breadcrumb {
        overflow: scroll;
        ul {
          flex-wrap: nowrap;
        }
      }
    }

    @include from($large-mobile-breakpoint) {
      .tile:not(.is-child) {
        display: flex;
      }
      .tile.is-6 {
        flex: none;
        width: 50%;
      }
    }
  }

  &.is-product-footer {
    @extend .has-background-dark;
    .bubble {
      position: relative;
      right: 4rem;
      top: -5rem;
      margin-right: -4rem;
    }

    @include until($tablet) {
      .level {
        display: flex;
        flex-direction: column;
        .level-right {
          display: flex;
          flex-direction: row;
        }
        .level-item:not(:last-child) {
          margin-right: .5rem;
        }
      }
    }
  }

  &.is-product-link {
    position: relative;
    display: block;

    .image {
      width: 100%;
      min-height: 300px;
      position: relative;
      background-color: $white;
      background-position: center top;
      background-size: contain;
      //background-size: cover;
      background-repeat: no-repeat;
      padding-top: 110%;

      .tag {
        margin-top: 1rem;
        margin-left: 1rem;
      }
    }

    .details-wrapper {
      position: absolute;
      z-index: 11;
      bottom: 0;
      width: 100%;
      text-align: center;
    }

    .details {
      @extend .has-text-centered;
      //padding: 1rem;
      //padding: 0.75rem;
      padding: 0.5rem;
      margin: 3px;
      display: inline-block;
      background-color: rgba(255, 255, 255, 0.96);
      //background-color: rgba(243, 237, 240, 0.96);
      //min-width: 70%;
      min-width: 80%;
      //width: 95%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 80px;
    }

    .pricing {
      .full-price {
        color: $brand-blue;
      }
    }

    .title {
      //@extend .is-size-4;
      @extend .is-marginless;
      //font-size: 1.125rem;
      font-size: 1rem;
      color: $secondary;
    }
    .subtitle {
      //@extend .is-size-6;
      @extend .is-marginless;
      font-family: $family-sans-serif;
      font-size: 0.5625rem;
      color: $secondary;
      text-transform: uppercase;
    }

    //@include until($large-mobile-breakpoint) {
    //  .image {
    //    min-height: 400px;
    //  }
    //}

    @include until($mobile-breakpoint) {
      //.image {
      //  min-height: 300px;
      //  background-position: center top;
      //  background-size: cover;
      //}
      .details {
        padding: 1rem;
      }
    }

    //@include from($large-laptop-breakpoint) {
    //  .image {
    //    //min-height: 540px;
    //    min-height: 380px;
    //  }
    //}
    //
    //@include from($fullhd-2k-breakpoint) {
    //  .image {
    //    min-height: 640px;
    //  }
    //}

    //@media screen and (max-height: 360px) {
    //
    //}
  }

  &.is-product-row {
    .title {
      margin: 0;
    }
    @include until($large-mobile-breakpoint) {
      .subtitle {
        padding-bottom: .75rem;
      }
    }
    .image {
      background-position: top center;
    }
    .pricing {
      @extend .has-text-right;
      font-family: $family-secondary !important;
      .price, .full-price {
        display: block;
      }
      //.subtotal {
      //  @extend .is-size-4;
      //  //font-family: $family-secondary !important;
      //}
      .saving {
        //font-family: $family-secondary !important;
      }
    }
    .quantity {
      form {
        display: inline-grid;
        input[type='number'] {
          font-size: 0.7rem;
          max-width: 60px;
        }
        button, .material-icons {
          font-size: 0.7rem;
        }
      }
    }
    .quantity-and-pricing {
      //.is-clearfix {
      //  display: none;
      //}
      @include until($large-mobile-breakpoint) {
        padding: 0.75rem!important;
      }
      @include from($large-mobile-breakpoint) {
        .is-clearfix {
          display: none!important;
        }
      }
    }

    .colors {
      .tag:not(:last-child) {
        margin-right: 0.5rem;
      }
    }

    .gifts {
      .pricing {
        float: right;
        .price, .full-price {
          font-size: $size-7 !important;
        }
      }
    }
  }
}

section.products {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .products-grid {
    max-width: 1920px;
    flex: 1 0 auto;
    width: 100%;
  }
}

.products-grid {
  .tile {
    @extend .is-paddingless;
    @extend .is-marginless;
    &.is-child {
      border: 0.5px solid $brand-light-gray;
    }
  }

  //a.product {
  //
  //  .image {
  //    //transition: all 0.5s ease;
  //    transition: all 0.25s ease-out;
  //  }
  //
  //  &:hover {
  //    .image {
  //      //background-size: cover;
  //      //background-size: 110%;
  //      //transition: all .5s;
  //      transform: scale(1.4) translateY(10%);
  //      //transform: scale(1.2);
  //    }
  //  }
  //
  //}

  .columns, .column {
    margin: 0;
    padding: 0;
    border: 0.5rem solid $brand-light-gray;
    overflow: hidden;
  }

  &.is-collapsed {
    @include until($widescreen) {
      .column:nth-child(n+9) {
        display: none!important;
      }
      &.is-small {
        .column:nth-child(n+5) {
          display: none!important;
        }
      }
    }
    @include from($widescreen) {
      .column:nth-child(n+11) {
        display: none!important;
      }
      &.is-small {
        .column:nth-child(n+6) {
          display: none!important;
        }
      }
    }
  }

  @include until($mobile-breakpoint) {
    .columns, .column {
      border: 0.25rem solid $brand-light-gray;
    }
  }

  @include from($large-mobile-breakpoint) {
    .columns {
      display: flex;
    }
    .column {
      flex: none;
      width: 50%;
    }
    //.column:nth-child(5n+1) {
    //  flex: none;
    //  width: 50%;
    //  .image {
    //    background-size: cover;
    //    background-position: center 10%;
    //  }
    //}
  }
  @include from($tablet) {
    .column {
      flex: none;
      width: 25%;
    }
  }
  @include from($widescreen) {
    .column {
      flex: none;
      width: 20%;
    }
  }
  //@include from(1600px) {
  //  .column, .column:nth-child(5n+1) {
  //    width: 20%;
  //  }
  //  .column:nth-child(7n+1), .column:nth-child(9n+1) {
  //    width: 40%;
  //  }
  //}
  //@include from(1921px) {
  //  .column, .column:nth-child(5n+1), .column:nth-child(7n+1) {
  //    width: 17.5%;
  //  }
  //  .column:nth-child(9n+1), .column:nth-child(12n+1) {
  //    width: 30%;
  //  }
  //}
}

.gifts {
  section, .section {
    margin: 0;
    padding: 0;
  }
  .products-grid {
    .columns, .column {
      border: none;
    }
    .columns {
      display: flex;
      //justify-content: space-around;
    }
    .column {
      //margin: 0.5rem 0.5rem 0 0;
      //padding-top: 0.5rem;
      width: 30%;
      background-color: $white;
      //flex: none;
      //flex: 1 0 auto;
      margin: 0 0.5rem 0.5rem 0;
      &:nth-child(3n) {
        margin: 0 0 0.5rem 0;
      }
    }
  }
  .product {
    &.is-product-label {
      @extend .is-product-link;

      //background-color: $white;
      padding: 0.3rem;

      .image {
        min-height: 70px;
        background-size: contain;
        padding-top: 0;
      }
      .details-wrapper {
        position: relative;
      }
      .details {
        padding: 5px;
        margin: 0;
        min-height: auto;
      }
      .title {
        font-size: 0.75rem!important;
      }
      .subtitle {
        font-size: 9px!important;
      }
      .pricing {
        float: none;
        text-align: center!important;

        .full-price {
          font-size: 9px!important;
        }
        .price {
          font-size: 0.75rem!important;
        }
      }
    }
  }

  @include until($large-mobile-breakpoint) {
    .columns {
      justify-content: space-around;
    }
    .column {
      flex: 1 0 auto;
    }
  }
}

/* mouse, touch pad */
@media (hover: hover) and (pointer: fine) {
  .products-grid {
    a.product {
      .image {
        transition: all 0.25s ease-out;
      }
      &:hover {
        .image {
          transform: scale(1.4) translateY(10%);
        }
      }
    }
  }
}