$loader-border-size: 2px!default;
.loader {
  border: $loader-border-size solid $brand-light-gray; // This is the bg color
  border-top: $loader-border-size solid $primary;

  //border-right: 16px solid green;
  //border-bottom: 16px solid red;
  //border-left: 16px solid pink;

  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  animation: spin 0.4s linear infinite;

  &.is-small {
    width: 1rem;
    height: 1rem;
  }
  &.is-medium {
    width: 2rem;
    height: 2rem;
  }
  &.is-large {
    width: 3rem;
    height: 3rem;
  }

  @each $color-name, $color in $brand-colors {
    &.is-#{$color-name} {
      border-top: $loader-border-size solid #{$color};
    }
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}