
.calendarContainer {
  font-family: $family-secondary;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 1.5rem;

  @include until($custom-mobile-breakpoint) {
    margin: 0;
    padding: 0;
  }

  .calendar {
    margin: 0;
    padding: 0;
    max-width: 300px;

    .title {
      margin: 0;
      padding: 0;
      font-family: $family-secondary;
      text-align: right;

    }

    .content, .legend {
      margin: 0;
      padding: 0;

      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: block;

        li {
          display:inline-block;
          width:38px;
          height:38px;
          vertical-align: top;
        }
      }
    }

    .content {
      margin: 0;
      padding: 0;

      ul {
        margin: 0;
        padding: 0;
        list-style:none;
        display:block;

        li {
          font-family: $family-secondary;

          display:inline-block;
          min-width:24px;
          min-height:24px;
          width:38px;
          height:38px;
          border:1px solid #999;
          cursor:pointer;
          margin:2px;
          padding:2px 0 2px 2px;
          color:#000;
          vertical-align:top;
          overflow:hidden;
          text-overflow:clip;
          font-size:9px;
          position: relative;

          .weekday {
            font-size:11px;
            color:#666666;
            display:block;
          }
          .day {
            font-size:1rem;
            display:inline-block;
          }
          .month {
            font-size:8px;
            //text-align:right;
            //margin-left:1px;
            color:#666666;
            //display:inline-block;
            position: absolute;
            bottom: 0px;
            right: 0px;
          }
        }
      }
    }

    .nav {
      width: 100%;
      margin: 0;
      padding: 0;
      font-family: $family-secondary;

      .next, .prev, .today {
        text-transform:capitalize;
        cursor:pointer;
        display:inline-block;
        float:right;
        margin: 5px;
        padding: 5px;
      }
      .today {
        float: inherit;
      }
    }

    .legend {
      margin: 0;
      padding: 0;

      font-size:10px;
      border-top:1px dashed #CCC;
      padding-top:10px;
      font-family: $family-secondary;

      .title {
        display: block;
        font-size: 9px;
        margin-bottom: 5px;
      }

      ul {
        li {
          cursor:help;
          margin:2px;
          padding:2px;
          //width:48px;
          width:54px;
          height:48px;
          word-wrap:break-word;
        }
      }
    }

    .content, .legend {
      .closed {
        background-color:$brand-light-pink;
        cursor:not-allowed;
      }
      .grouped {
        background-color:#fff6ea;
      }
      .booked {
        background-color:#fff0fe;
        cursor:not-allowed;
      }
      .weekend {
        //background-color:lighten($brand-light-teal, 20%);
      }
      .selected {
        background-color:$brand-green;
      }
      .disabled {
        color:#CCC;
        background-color:#FFF;
        border:1px solid #e5e5e5;
        cursor:not-allowed;
      }
      .today {
        border:1px solid $primary;
      }
      .clear {
        color:#000;
        background-color:#FFF;
        border:1px solid #000;
      }
    }
  }
}
