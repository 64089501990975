

.modal-button {
  &.image {
    cursor: zoom-in;
  }
}
.modal-auto {
  .modal-content {
    width: calc(100vw - 40px);
    //width: 100%;
  }
}

.modal-close {
  @extend .has-background-primary;
}

//@include mobile {
//  .modal-auto {
//    .modal-content {
//      //width: 100%;
//    }
//  }
//}