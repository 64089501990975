$blog-article-max-width: calc(740px + 2em);

.blog-article {
  //justify-content: center;
  //align-content: center;
  //display: flex;
  margin: auto;
  max-width: $blog-article-max-width;

  @extend .has-background-white;
  padding: 0;

  .content {
    //@extend .has-background-white;
    //max-width: $blog-article-max-width;
    padding: 3rem 1.5rem;

    @include tablet {
      padding: 2rem 1rem;
    }
    //@include mobile {
    //  padding: 0.5rem;
    //}

    a {
      text-decoration: underline;
      color: $blue;
    }
  }
  &.is-small {
    .content {
      max-width: 640px;
    }
  }
  &.is-medium {
    .content {
      max-width: 920px;
    }
  }
  &.is-large {
    .content {
      max-width: inherit;
    }
  }
}

.section ~ .section.blog-article {
  padding: 3rem 1.5rem;
}

@include until($large-mobile-breakpoint) {
  .blog-article {
    .content {
      padding: 1rem 0.5rem 1rem 0.5rem;

      figure {
        margin: 0;
      }

      .image {
        min-height: inherit;
      }

      blockquote {
        font-size: $size-5 !important;
      }
    }
  }
}

.article-box {
  min-width: 240px;
  display: block;

  .title {
    @extend .is-size-4;
  }
  hr {
    margin: .5rem 0;
  }
  .content {
    @extend .is-size-6;
  }
}