$arc-diameter: 170px;

.sun {
  .sunmoon {
    position: relative;
    & > div {
      margin-left: 10px
    }
  }


  .sun-times {
    margin-top: 40px;
    width: 230px;
    height: 60px;
    border-bottom: 1px solid #999;
    overflow-y: hidden;

    .sun-path {
      margin-left: 25px;
      width: $arc-diameter;
      height: $arc-diameter;
      overflow: hidden;
      border: 1px dashed #999;
      border-radius: 50%;
    }

    .sun-symbol-path {
      position: absolute;
      color: yellow;
      text-shadow: 0 0 5px black;
      height: $arc-diameter / 2;
      -webkit-transition: -webkit-transform 2s linear;
      -webkit-transform-origin: 50% 100%;
      -webkit-transform: rotateZ(-75deg);
      left: ($arc-diameter / 2) + 25px;
      bottom: 0;

      .symbol {
        position: relative;
        font-size: 16px;
        top: -8px;
      }
    }

    .sun-animation {
      width: 0px;
      height: 150px;
      background-color: rgba(255, 255, 0, 0.4);
      -webkit-transition: width 2s linear;
      transition: width 2s linear;
    }
  }


  .legend {
    position: absolute;
    bottom: 1em;

    & > div {
      position: absolute;
      font-size: 12px;
      width: 80px;
    }

    .sunrise {
      left: 15px;
    }

    .sunset {
      left: 185px;
    }
  }
}