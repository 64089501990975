@charset "utf-8";

// Import config
@import "_config";

// Import External before utilities
// (this way the style is properly reset)

/**
Bulma Steps
 */
$steps-default-color: $brand-light-pink;
$steps-completed-color: $primary;
$steps-active-color: $primary;
@import "../../../node_modules/bulma-o-steps/bulma-steps";
/**
Bulma Badge
 */
@import "../../../node_modules/bulma-badge/src/sass/index";

/**
Bulma
 */
// Import Utilities

@import "../../../node_modules/bulma/sass/utilities/all";
@import "../../../node_modules/bulma/sass/base/all";
@import "../../../node_modules/bulma/sass/helpers/all";

@import "../../../node_modules/bulma/sass/elements/all";

$navbar-breakpoint: $tablet;
$breadcrumb-item-separator-color: $secondary;
@import "../../../node_modules/bulma/sass/components/all";
@import "../../../node_modules/bulma/sass/layout/all";
@import "../../../node_modules/bulma/sass/grid/all";
@import "../../../node_modules/bulma/sass/form/all";

/**
Custom
 */




//.button {
//  font-family: $family-secondary;
//  &.is-primary {
//    //color: $secondary;
//    color: findColorInvert($primary);
//    &:hover,
//    &.is-hovered,
//    &:focus,
//    &.is-focused{
//      color: lighten(findColorInvert($primary), 20%);
//    }
//  }
//}


.is-sale {
  @extend .is-primary;
  //@extend .has-text-dark;
}
//.is-sold {
//  @extend .is-info;
//}
.is-new {
  @extend .is-danger;
  //background-color: $brand-teal !important;
}
.is-discount {
  @extend .is-danger;
}


.media + .media {
  border: none;
}

.grecaptcha-badge {
  display: none !important;
}

$image-size: 600px;
$image-size-half: 300px;

.is-image {
  &.is-full-size {
    width: $image-size;
    height: $image-size;
  }
  &.is-half-size {
    width: $image-size-half;
    height: $image-size-half;
  }
}

.image {
  &.thumbnail {
    width: 3rem!important;
    height: 3rem!important;;
  }
}


@import "_helpers";
@import "logo";
@import "button";
@import "cover";
@import "section";
@import "stars";
@import "product";
@import "cart";
@import "blog";
@import "modal";
@import "material";
@import "calendar";
@import "navbar";
@import "footer";
@import "pagination";
@import "testimonials";
@import "tags";
@import "loader";
@import "input";
@import "gallery";

ul {
  &.has-icons-left, &.has-icons-right {
    li {
      display: flex;
      align-items: center;
    }
  }
  &.has-icons-right {
    li {
      flex-flow: row-reverse;
    }
  }
  &.is-spaced {
    li {
      margin-bottom: 1rem;
    }
    &.has-icons-left {
      .material-icons {
        margin-right: 1rem;
      }
    }
    &.has-icons-right {
      .material-icons {
        margin-left: 1rem;
      }
    }
  }
}


.is-logo {
  color: $primary;
  font-family: $family-sans-serif;
  //text-decoration: none;
  //text-transform: lowercase;
  font-weight: bold;
  font-style: italic;
}


.is-curly-divider {
  //width: 30%;
  //color: $primary;
  //color: opacify($primary, 0.5);
  color: $brand-light-pink;
  //content: attr("data-content");
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  &:before, &:after {
    content: "∙";
    color: $primary !important;
    font-size: 1em;
    padding: 1rem 3rem;
  }
}

@include until($tablet) {
  .section.is-title-subtitle {
    padding-bottom: 0;
    .title {
      font-size: $size-3!important;
    }
    .subtitle {
      font-size: $size-5!important;
    }
  }
}

@include until($large-mobile-breakpoint) {
  .section {
    padding: 3rem 0.5rem 1rem 0.5rem;
  }
  h1 {
    font-size: $size-3!important;
  }
  h2 {
    font-size: $size-4!important;
  }
  h3 {
    font-size: $size-5!important;
  }
  h4 {
    font-size: $size-6!important;
  }
  h5 {
    font-size: $size-6!important;
  }
  h6 {
    font-size: $size-smallest!important;
  }

  .is-hidden-mobile-large {
    display: none;
  }

  .has-hero-height {
    min-height: 19rem;
  }
}

@include from($desktop) {
  .reset-visibility-from-desktop {
    display: block!important;
  }
}
@include from($tablet) {
  .reset-visibility-from-tablet {
    display: block!important;
  }
}
@include from($large-mobile-breakpoint) {
  .reset-visibility-from-mobile-large {
    display: block!important;
  }
}
@include from($custom-mobile-breakpoint) {
  .reset-visibility-from-mobile {
    display: block!important;
  }
}

@media print {
  .no-print, .no-print * {
    display: none !important;
  }
}