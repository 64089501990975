
.pagination {
  padding: 1.5rem;
  @include until($custom-mobile-breakpoint) {
    padding: .5rem;

    .pagination-link {
      min-width: auto;
      font-size: $size-7;
    }
  }
}
