
.cover-fill {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.cover-contain {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.cover-empty {
  background-size: inherit;
  background-repeat: inherit;
}


@include until($large-mobile-breakpoint) {
  .cover-contain-mobile {
    background-size: contain!important;
  }
  .cover-fill-mobile {
    background-size: cover!important;
  }
}