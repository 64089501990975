@charset "utf-8";

//$brand-green: #DCE27F;
$brand-green: #179E97;
$brand-light-green: #eff2de;
$brand-teal: #65c4bc;
$brand-light-teal: #9ad8d3;
$brand-blue: #041F60;
$brand-pink: #FD1895;
$brand-dark-pink: #531338;
$brand-light-pink: #fdbecf;
$brand-gray: #8f9093;
$brand-light-gray: #f8f8f8;
$brand-light-gray-pink: #f7f5f6;
$brand-light-gray-teal: #F5F7F7;
$brand-dark-brown: #271911;
$brand-dark-blue: #010A21;

$sector-mobility: #179E97;
//$sector-security: #FFDE59;
$sector-security: #f78a00;
$sector-healthcare: #F01447;
$sector-energy: #FD1895;
$sector-water: #3E6CDB;
//$sector-waste: #86CE25;
$sector-waste: #34B34C;
$sector-economic-development: #652FC7;
$sector-engagement: #B924BF;

$smart-city: $sector-mobility;
$smart-grid: $sector-economic-development;
$smart-home: $sector-security;

$tech-zigbee: #ec0444;
$tech-lorawan: #00aeef;
$tech-ltem: #b60e0c;
$tech-nbiot: #00704a;

//$grey-darker: $brand-purple;
//$grey-dark: lighten($grey-darker, 10%);
$grey-darker: darken($brand-dark-blue, 3%);
$grey-dark: $brand-dark-blue;

$grey-light: #EDEFF2;
$dark: $brand-dark-blue;
$light: $grey-light;

//$primary: $brand-blue;
$primary: $brand-pink;
$secondary: $brand-green;

$table-row-hover-background-color: lighten($secondary, 60%);
$table-striped-row-even-hover-background-color: lighten($secondary, 55%);

$link: $brand-pink;

$family-sans-serif: 'Source Sans Pro', sans-serif;
$family-serif: 'Source Serif Pro', serif;
$family-monospace: 'Source Code Pro', monospace;

$family-primary: $family-sans-serif;
$family-secondary: $family-sans-serif;

//$title-family: $family-monospace;
//$subtitle-family: $family-monospace;

$title-weight: inherit;

$breakpoint-GalaxyS5-w: 361px; // 360 + 1
$breakpoint-GalaxyS5-h: 641px; // 640 + 1
$breakpoint-iPhone6-w: 376px; // 375 + 1
$breakpoint-iPhone6-h: 667px; // 812 + 1
$breakpoint-iPhoneX-w: 376px; // 375 + 1
$breakpoint-iPhoneX-h: 813px; // 812 + 1
$breakpoint-iPhonePlus-w: 415px; // 414 + 1
$breakpoint-iPhonePlus-h: 737px; // 736 + 1

$mobile-breakpoint: 361px;
$custom-mobile-breakpoint: $breakpoint-iPhoneX-w;
$large-mobile-breakpoint: 561px;
$large-laptop-breakpoint: 1537px;
$fullhd-2k-breakpoint: 1921px;

//$widescreen-enabled: false;
//$fullhd-enabled: false;

// Update some of Bulma's component variables
$body-background-color: $grey-light;
//$control-border-width: 0px;
//$input-border-color: transparent;
//$input-shadow: none;

//$footer-background-color: $dark;

$size-smallest: 8px;

//$radius-small: 0px;
$radius: 8px;
//$radius-large: 0px;

$box-radius: 0px;

$content-blockquote-background-color: transparent;
$content-blockquote-border-left: 2px solid $brand-blue;

///* smartphones, touchscreens */
//@media (hover: none) and (pointer: coarse) {
//  /* ... */
//}
///* stylus-based screens */
//@media (hover: none) and (pointer: fine) {
//  /* ... */
//}
///* Nintendo Wii controller, Microsoft Kinect */
//@media (hover: hover) and (pointer: coarse) {
//  /* ... */
//}
///* mouse, touch pad */
//@media (hover: hover) and (pointer: fine) {
//  /* ... */
//}

$brand-colors: (
brand-green : $brand-green,
brand-light-green: $brand-light-green,
brand-teal : $brand-teal,
brand-light-teal: $brand-light-teal,
brand-blue: $brand-blue,
brand-pink: $brand-pink,
brand-dark-pink: $brand-dark-pink,
brand-light-pink: $brand-light-pink,
brand-gray: $brand-gray,
brand-light-gray: $brand-light-gray,
brand-light-gray-pink: $brand-light-gray-pink,
brand-light-gray-teal: $brand-light-gray-teal,
brand-dark-brown: $brand-dark-brown,
brand-dark-blue: $brand-dark-blue,

sector-mobility: $sector-mobility,
sector-security: $sector-security,
sector-healthcare: $sector-healthcare,
sector-energy: $sector-energy,
sector-water: $sector-water,
sector-waste: $sector-waste,
sector-economic-development: $sector-economic-development,
sector-engagement: $sector-engagement,

smart-city: $smart-city,
smart-grid: $smart-grid,
smart-home: $smart-home,
smartcity: $smart-city,
smartgrid: $smart-grid,
smarthome: $smart-home,

tech-lorawan: $tech-lorawan,
tech-ltem: $tech-ltem,
tech-nbiot: $tech-nbiot,
tech-zigbee: $tech-zigbee,

primary: $primary,
secondary: $secondary,
dark: $dark,
grey-dark: $grey-dark,
grey-darker: $grey-darker,
grey-light: $grey-light,

);

$custom-colors: $brand-colors;

// NOT NEEDED as it's automatically generated from $custom-colors
//@each $color-name, $color in $brand-colors {
//  .has-background-#{$color-name} {
//    background-color: #{$color}!important;
//  }
//  .has-color-#{$color-name} {
//    color: #{$color}!important;
//  }
//  .has-text-#{$color-name} {
//    color: #{$color}!important;
//  }
//}

hr, .hr {
  @each $color-name, $color in $brand-colors {
    &.is-#{$color-name} {
      background-color: #{$color}!important;
    }
  }
}