
.stars {
  color: $brand-green;
  .material-icons {
    vertical-align: middle;
  }
  .rating {
    display: inline-block;
    &:after {
      @extend .is-size-7;
      content: attr(data-text);
      vertical-align: middle;
      font-family: $family-secondary;
    }
  }
}
