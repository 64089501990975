a.logo {
  color: $primary;
  font-family: $family-sans-serif;
  text-decoration: none;
  text-transform: lowercase;
  font-weight: bold;
  font-style: italic;
  font-size: 2rem;
  //strong {
  //  color: $primary;
  //  font-weight: bold;
  //}

  img {
    height: 40px;
    max-height: none;
  }
}