
$gallery-image-size: 420px;
$gallery-image-size-thumb: 90px;


.gallery {
  .is-image {
    cursor: pointer;
    min-height: $gallery-image-size-thumb;
  }
  .is-main-image {
    cursor: zoom-in;
  }

  .thumbnails {
    //display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .is-thumbnail {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;

    flex-basis: 0;
    flex-grow: 0;
    flex-shrink: 0;
  }

  //@include until($large-mobile-breakpoint) {
  @include until($tablet) {
    display: flex;
    flex-direction: column-reverse;

    .thumbnails {
      display: flex;
      flex-direction: row;
    }

    .is-thumbnail {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;

      //flex-grow: 1;
      min-width: 60px;
      min-height: 90px;
      max-height: 100px;

      margin-right: 0.5rem;

      &:last-child {
        margin-right: 0!important;
      }
    }
  }
}
