footer.footer {

  .column.links {
    .title {
      font-size: $size-6;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        a {
          font-size: $size-7;
        }
      }
    }
  }

  //@include until($custom-mobile-breakpoint) {
  //
  //}
  //
  //@include until($mobile-breakpoint) {
  //
  //}
  //
  //@media screen and (min-width: $navbar-breakpoint) and (max-width: 800px) {
  //
  //}
  //@media screen and (min-width: $navbar-breakpoint) and (max-width: 1000px) {
  //
  //}
  //@media screen and (min-width: $navbar-breakpoint) and (max-width: 1200px) {
  //
  //}
}