
.cart, .order-info, .order {
  overflow: hidden;

  .overview {
    //font-family: $family-secondary;
    //.itemsTotal, .shippingPrice, .total {
    //  font-family: $family-secondary;
    //}
    .total {
      font-family: $family-secondary;
    }
  }

  h1 {
    font-size: $size-4 !important;
  }

  .incentives {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .mobile-header {
    display: none;

    .has-text-right {
      margin-top: 0.5rem;
    }
  }

  .is-product-row {
    .image {
      min-width: auto;
      min-height: auto;
      width: 10rem;
      height: 10rem;
      float: left;
      margin-right: 1rem;
    }

    .title {
      @extend .is-spaced;
    }

    .subtitle {
      @extend .is-marginless;
      //font-size: $size-5 !important;
    }

    .pricing {
      @extend .has-text-right;

      .full-price {
        margin: 0 !important;
      }
    }

    .level.quantity {
      margin: 0 !important;
    }
  }

  @include until($tablet) {
    padding: 3rem 0.5rem 1.5rem 0.5rem !important;

    h1 {
      font-size: $size-5 !important;
    }

    .mobile-header {
      display: block;

      .columns {
        display: flex;
      }
    }

    .products {
      padding: 0.5rem;
    }

    .is-product-row {
      .image {;
        width: 5rem;
        height: 5rem;
      }
    }
  }

  @include until($custom-mobile-breakpoint) {
    padding: 3rem 0.25rem 1.5rem 0.25rem !important;

    h1 {
      font-size: $size-6 !important;
    }

    .mobile-header {

      //position: fixed;
      //left: 0;
      //bottom: 0;
      //width: 100%;
      ////background-color: $white;
      //padding: 1rem;
      //z-index: 99999999;

      .columns {
        flex-direction: row;
      }

      .column:first-child {
        display: none;
      }
    }

    .products {
      padding: 0.25rem;
    }

    .is-product-row {
      .image {;
        width: 4rem;
        height: 4rem;
      }

      //.title {
      //  font-size: $size-4 !important;
      //}
      //
      //.subtitle {
      //  font-size: $size-6 !important;
      //}

      .column {
        padding-top: 0;
        padding-bottom: 0;
      }

      .quantity {
        margin: 0;
      }

      .pricing.columns {
        display: flex;
        flex-direction: row-reverse;
      }
    }
  }

  //@media screen and (min-width: 560px) and (max-width: $tablet - 1px) {
  @media screen and (min-width: 560px) {
    .is-product-row {
      display: flex;
    }
    .level.quantity {
      flex-direction: column;
      align-items: flex-end;
      //.level-item {
      //  margin: 0
      //}
      //.level-left, .level-right {
      //  //align-items: end;
      //  //justify-content: flex-end;
      //}
    }
  }
}

.is-order-details, .is-order-list {
  .steps {
    overflow: scroll;
    margin: 0;
  }
}

@include from($tablet) {
  .order-columns {
    flex-direction: row-reverse;
  }
}

.total-container {
  &.is-mobile-only {
    display: inherit;
  }
  .dropdown-menu {
    z-index: 999;
    .tag {
      white-space: inherit;
      height: auto;
    }
  }
}

.delivery-info {
  .tabs {
    a {
      font-size: $size-7;
      input[type='radio'] {
        pointer-events: none;
      }
    }
  }
  .delivery-mode {
    border: 1px solid #dbdbdb;
    border-top: 0;
    padding: 0.5rem;
  }
}

@include until($custom-mobile-breakpoint) {
  .order-info {
    .box {
      padding: 0.5rem;
    }
  }
  .is-order-details {
    .box {
      padding: 0.5rem;
    }
  }
  .tabs {
    font-size: $size-7;
  }
}

@include until($large-mobile-breakpoint) {
  .total-container {
    &.is-mobile-only {
      display: flex;
      margin-bottom: 0;
      line-height: 1;
      align-items: baseline;
      justify-content: space-between;
    }
  }
}